.summary-graph {
  padding-top: 20px;
}

.summary-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-table {
  padding-top: 20px;
}

.summary-table:first-of-type {
  padding-top: 0px;
}