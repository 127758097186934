.element-achievement {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
  margin: 0;
}

.edit-achievement {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  margin: 0;
}

.title-achievement {
  text-align: left!important;
}