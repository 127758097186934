
.leftsection-admin {
  position:absolute;
  height:100%!important;
  float: left;
  min-width: 300px;
  max-width: 400px;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  background-color: #31404C;
}

.mainsection-admin {
  margin-left: 320px;
  padding: 1em;
  overflow: hidden;
}
