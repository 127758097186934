.tabs-header {
  padding-top: 30px;
}

.bp3-tab-list{
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .bp3-tab-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
  }
}