@media only screen and (max-width: 650px) {
  a.bp3-button{
    display: none !important;
  }
  .bp3-navbar-group{
    display: flex;
    flex-direction: row;
  }
  .navbar-container{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 20;
  }
}