.input {
  width: 50px;
}

.timesheet-table {
  padding-top: 20px;
}

.customer-logo{
  max-width: 120px;
}

.task-logo-container{
  display: flex;
  flex-direction: column;
}

.buttons-container{
  display: flex;
  flex-direction: row;
}

.avatar-name-container{
  display: flex;
  flex-direction: row;
}

.input-container {
  display: flex;
  flex-direction: row;
}

.timesheet-user-info-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.aligned-comments {
  display: flex;
  align-items: center;
}

.icon-comment {
  margin-left: 30%;
}

@media only screen and (max-width: 650px) {
  .timesheet-user-info-container{
    display: flex;
    flex-direction: column;
  }
  td.pivoted{
    padding-bottom: 30px !important;
  }
  .status-container{
    padding-top: 10px;
  }
}

.day_comment:hover{
  cursor: pointer;
}

.day_comment .icon-comment:focus{
  outline: none;
}

.subtask-name {
  margin-left: 20px;
}
