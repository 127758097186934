.user-principal-info-container{
  display: flex;
  flex-direction: row;
}

.name-email-container{
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.name-role-container{
  display: flex;
  flex-direction: row;
}

.email-container{
  overflow-wrap: break-word;
}

@media only screen and (max-width: 600px) {
  .name-email-container{
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    width: 45%;
  }
}