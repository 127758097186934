.summary-leftsection {
  float: left;
  max-width: 320px;
  margin: 0;
  padding: 0;
  padding-top: 30px;
}

.summary-mainsection {
  margin-left: 170px;
  padding: 1em;
  overflow: hidden;
}

@media only screen and (max-width: 650px) {
  .summary-mainsection {
    margin-left: 0px;
    padding: 1em;
    width: 100%;
    overflow: unset;
  }
}