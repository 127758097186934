.button-space {
  margin-right: 5px!important;
}

.Selectable .DayPicker-Day--hoverRange {
  background-color: #607382  !important;
}
.Selectable .DayPicker-Day--selected {
  background-color: #3A4B58 !important;
  color: 'white';
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--hoverRangeStart,
.Selectable .DayPicker-Day--selectedRangeStart {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--hoverRangeEnd,
.Selectable .DayPicker-Day--selectedRangeEnd {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.range-example {
  display: inline-block;
}

.date-inputs-div {
  display: flex;
  justify-content: center;
  margin: 0 1em 1em;
}

.bp3-input-group {
  margin: 0 5px;
}

@media only screen and (max-width: 650px) {
  .selectday-container{
    padding-left: 25px;
  }
}
