body{
  padding:0;
  margin:0;
  background-color: #25303A;
}

@media only screen and (max-width: 650px) {
  html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px !important;
   }

}
