.select-control {
  height: 30px;
  width: 350px;
}

.select-placeholder {
  line-height: 26px;
}

.select-value {
  line-height: 26px !important;
}

.select-value-label {
  line-height: 26px;
}

.select-input {
  text-align: left;
  width: 250px;
  max-width: 100%;
  height: 26px;
}

.select-input-task {
  text-align: left;
  width: 250px;
  max-width: 100%;
}