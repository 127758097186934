.timesheet-leftsection {
  float: left;
  max-width: 300px;
  margin: 0;
  padding: 0;
  padding-top: 30px;
}

.timesheet-mainsection {
  margin-left: 170px;
  padding: 1em;
  overflow: hidden;
}

@media only screen and (max-width: 1300px) {
  .timesheet-container{
    display: flex;
    flex-direction: column;
  }
  .timesheet-mainsection {
    padding: 1em;
    overflow: hidden;
    margin-left: 0px;
    /* overflow: scroll;
    display: flex;
    flex-wrap: wrap; */
  }
  .timesheet-leftsection {
    float: left;
    max-width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 15px;
  }
}