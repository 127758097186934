.users-card-space {
  margin-top: 15px;
  margin-bottom: 15px;
}
.achievements-scores-container{
  padding-right: 20px;
}

@media only screen and (max-width: 650px) {
  .row{
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .achievements-container{
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .achievements-scores-container{
    padding-right: 0px;
  }
}