.board-message {
  padding-top:20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.element-message {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
  margin: 0;
}

.edit-message {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  margin: 0;
}

.title-message {
  text-align: left !important;
}

.Message {
  display: flex;
  flex-direction: column-reverse;
}

.text-area-edit{
  height: 250px !important;
}