.daily-task-container{
  padding: 20px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.date-info-container{
  width: 120px;
  padding-right: 2px;
}

.note-container{
  width: 100%;
  padding-left: 10px;
}