
.loginbox{
  display: flex;
  flex-direction: row;
  position:absolute;
  height:100%;
  width:100%;
  font-family:Helvetica;
  color:white;
  background:rgba(0,0,0,0.05);
  padding:0px 0px;
}
.loginmodal{
  position:relative;
  height:100%;
  width:35%;
  float: right;
  padding:50px 20px 20px;
  background:rgba(0,0,0,0.15);
}

.message{
  position:relative;
  height:100%;
  width:65%;
  float: left;
  padding:50px 50px 20px;
  overflow: none;
}
.loginbox h1.bp3-heading{
  text-align:center;
  margin:30px 0;
  font-size:30px;
}
.loginbox input{
  display:block;
  width:300px;
  margin:20px auto;
  padding:15px;
  background:rgba(0,0,0,0.2);
  color:white;
  border:0;
}
.loginbox input:focus,.loginbox input:active,.loginbox button:focus,.loginbox button:active{
  outline:none;
}

.loginbox p{
  font-size:14px;
  text-align:center;
}

.google-button {
  padding-top: 50px;
}


@media only screen and (max-width: 1000px) {
  .loginbox{
    display: flex;
    flex-direction: column-reverse;
    position:absolute;
    height:100%;
    width:100%;
    font-family:Helvetica;
    color:white;
    background:rgba(0,0,0,0.05);
    padding:0px 0px;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .loginmodal{
    position: relative;
    height: 25%;
    width: 100%;
    float: right;
    padding:15px 20px 20px;
    background:rgba(0,0,0,0.15);
  }
  .message{
    position:relative;
    height:100%;
    width:100%;
    float: left;
    padding:20px 20px 20px;
    overflow: scroll;

  }
  .avatar-container{
    display: none;
  }
  .loginbox h1.bp3-heading{
    text-align:center;
    margin:15px 0;
    font-size:30px;
  }
}
