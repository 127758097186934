.box-commentary {
  padding-top:20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.comment {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
  margin: 0;
}

.edit-comment {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  margin: 0;
  text-align: left!important;
}