.dashboard-box {
  padding: 20px;
}
.user-info-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .user-info-container{
    display: flex;
    flex-direction: column;
  }
  .dashboard-box {
    padding: 20px;
    margin-top: 50px;
    padding-bottom: 50px;
  }
}