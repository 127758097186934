.message-box h1.bp3-heading{
  text-align: left;
}

.message-box p{
  text-align: left;
}

.message-box img{
  width: 120px;
}