.message-container-editor{
  width: 100%;
}

.message-container-editor p{
  text-align: left !important;
}

.message-container-editor h1.bp3-heading{
  text-align: left !important;
}

.message-container-editor img{
  width: 120px;
}

.editor-button{
  margin-top: 5px;
}

.react-mde {
  border-radius: 2px;
  border: none !important;
 }

 .mde-header {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid black;
  border-radius: 2px 2px 0 0;
  background: #394b59;
  color: white;
}

.mde-header button{
  color: white;
}

.mde-header ul.mde-header-group li.mde-header-item button {
  text-align: left;
  cursor: pointer;
  height: 22px;
  padding: 4px;
  margin: 0;
  border: none;
  background: none;
  color: white;
}

.mde-preview{
  background-color: rgba(16,22,26,.8);
  min-height: 110px;
}

.mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button {
  display: block;
  height: auto;
  color: black;
}

textarea.mde-text {
  width: 100%;
  border: 0;
  padding: 10px;
  vertical-align: top;
  resize: none;
  overflow-y: auto;
  outline: none;
  background-color: rgba(16,22,26,.8);
  color: white;
  caret-color: white;
  min-height: 110px;
 }

 .react-mde .grip {
  background-color: #394b59;
  text-align: center;
  height: 10px;
  color: white;
  cursor: s-resize;
  border: none;
 }
  .react-mde .grip .icon {
    height: 14px;
  }
