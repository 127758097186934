.timesheet-controller-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timesheet-controllers{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.weektask-controllers{
  padding-right: 10px;
}

@media only screen and (max-width: 600px) {
  .timesheet-controller-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .timesheet-controllers{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
  }
  .weektask-controllers{
    padding-top: 10px;
  }
}